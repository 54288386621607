export default api => ({

  getPersonRelatives(personId) {
    return api.hosts.v3.get(`person/relation?person=${personId}&__format=json`);
  },

  /*
  queryParams: {
    enrollmentStatus: 32
  }
  */
  getPersonChildren(personId, queryParams = null) {
    return api.get(`v3/people/${personId}/relatives/children`, queryParams);
  },

  getPersonSpouses(personId, queryParams = null) {
    return api.get(`v3/people/${personId}/relatives/spouses`, queryParams);
  },

  putPerson(personId, personData) {
    return api.put(`v3/people/${personId}`, personData);
  },

  /*
  POST v3/people/${personId}/relatives/spouses

  {
    firstname: 'Person firstname',
    lastname: 'Person firstname',
    gender: 1 | 0

    // Opcional: arreglo de personIds a crear como HIJOS
    children: [123, 456]
  }
  */
  createSpouse(personId, spouseData) {
    return api.post(`v3/people/${personId}/relatives/spouses`, spouseData)
  },

  getDataClinic(personId) {
    return api.hosts.v3.get(`person/clinic?person=${personId}&__format=json`);
  }

})