<template>
  <ui-item
    :text="fullName"
    :secondary="personSecondary"
    v-bind="$props"
    v-on="$listeners"
  >
    <template #icon>
      <ui-avatar
        :src="avatar"
        :title="fullName"
        :size="avatarSize"
      ></ui-avatar>
    </template>

    <template
      v-if="$slots['secondary']"
      #secondary
    >
      <slot name="secondary"></slot>
    </template>

    <template
      v-if="$slots['right']"
      #right
    >
      <slot name="right"></slot>
    </template>
  </ui-item>
</template>

<script>
import UiItem from '@/modules/ui/components/UiItem/UiItem.vue'; // import the full component since we need to extend it
import { UiAvatar } from '@/modules/ui/components';

export default {
  name: 'person-list-item',
  extends: UiItem, // Heredar las "props" de UiItem

  components: {
    UiAvatar,
    UiItem,
  },

  props: {
    person: {
      type: Object,
      required: true,
    },

    formal: {
      // Mostrar "Apellido, Nombre" (true)   o  "Nombre Apellido" (false)
      type: Boolean,
      required: false,
      default: false,
    },

    secondary: {
      required: false,
      default: null,
    },

    avatarSize: {
      required: false,
      default: 34,
    },
  },

  computed: {
    avatar() {
      if (
        this.person.avatar &&
        this.person.avatar.indexOf('default_person') == -1
      ) {
        return this.person.avatar;
      }

      if (
        this.person.picture &&
        this.person.picture.indexOf('default_person') == -1
      ) {
        return this.person.picture;
      }

      return null;
    },

    fullName() {
      let firstName = this.person.firstName || this.person.firstname || '';
      let lastName = this.person.lastName || this.person.lastname || '';

      if (this.person.lastName2) {
        lastName = lastName + ' ' + this.person.lastName2;
      }

      if (!firstName && !lastName) {
        return '?';
      }

      return this.formal
        ? `${lastName}, ${firstName}`
        : `${firstName} ${lastName}`;
    },

    personSecondary() {
      if (this.secondary) {
        return this.secondary;
      }

      if (
        this.$attrs.secondaryField &&
        typeof this.person[this.$attrs.secondaryField] != 'undefined'
      ) {
        return this.person[this.$attrs.secondaryField];
      }

      if (this.person.documentType && this.person.document) {
        return `${this.person.documentType} ${this.person.document}`;
      }

      let typeNames = Array.isArray(window?.PersonDocumentTypes)
        ? window?.PersonDocumentTypes
        : [
            { value: '1', text: 'T.I.' },
            { value: '2', text: 'C.C.' },
            { value: '3', text: 'C.E.' },
            { value: '4', text: 'R.C.' },
            { value: '5', text: 'NIT.' },
            { value: '6', text: 'PP.' },
            { value: '7', text: 'NUIP.' },
            { value: '9', text: 'T.E' },
            { value: '10', text: 'D.I.E' },
            { value: '11', text: 'NIT.E' },
            { value: '12', text: 'PEP' },
            { value: '8', text: 'OTRO' },
          ];

      if (typeof this.person.idtype != 'undefined' && this.person.document) {
        const foundIdType = typeNames.find((t) => t.value == this.person.idtype)
        let typeName = foundIdType?.text || this.person.idtype;
        return `${typeName} ${this.person.document}`;
      }

      return null;
    },
  },
};
</script>