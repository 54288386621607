<template>
  <phi-page color="#f2f2f2">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />

      <h1>{{ $t("PersonChildren.children") }}</h1>
    </div>

    <div class="PersonChildren">
      <router-link
        v-for="(child,i) in children"
        :key="i"
        :to="{name: 'classroom', params: {personId: child.person.id}}"
      >
        <PersonItem
          :person="child.person"
          class="ui-clickable"
        ></PersonItem>
      </router-link>
    </div>
  </phi-page>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import apiPerson from '@/modules/v3/api/person';

import PersonItem from '@/modules/core/components/Person/ListItem.vue';

export default {
  mixins: [useApi],
  api: apiPerson,

  components: {
    PersonItem,
  },

  data() {
    return {
      personId: this.$route.params.personId,
      children: [],

      yearId: null,
      periodId: null,
      currentView: 'grid', // list, grid, column (opciones admitidas por AcademicGroups)
    };
  },

  mounted() {
    this.fetchChildren();
  },

  methods: {
    async fetchChildren() {
      this.children = await this.$api.getPersonChildren(this.personId);
    },

    onClickGroup(group) {
      this.$router.push(
        `/people/${this.personId}/classroom/${group.id}/?period=${this.periodId}`
      );
    },

    onPeriodsFetched(categories) {
      if (this.periodId || !categories.length) {
        return;
      }

      let now = Math.floor(new Date().getTime() / 1000);
      let currentPeriod = categories[0].periods.find(
        (p) => p.start_date < now && now < p.end_date
      );
      if (!currentPeriod) {
        currentPeriod = categories[0].periods[0];
      }

      this.periodId = currentPeriod.id;
    },
  },

  i18n: {
    es: {
      'PersonChildren.children': 'Consulta Aula',
    },

    en: {
      'PersonChildren.children': 'Children',
    },
  },
};
</script>

<style lang="scss">
</style>